.nav-reports-list {
  margin: 2rem 1.5rem 0rem 2.4rem;
  .nav-reports-subhead {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0 0 0.3rem 0;
    color: #444444;
  }
}

.side-nav-top-head {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}

.nav-menu-top {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1rem 1rem 1rem 2.3rem;
  margin: 0 0 0.5rem 0;
  border-bottom: 0.1rem #cccccc solid;
  gap: 1rem;
  position: relative;

  .nav-side-bar {
    position: absolute;
    left: 0;
    width: 0.35rem;
    height: 3rem;
    background-color: #1e86f0;
  }

  .side-logo {
    width: 10rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
  }
}

.edit-icon {
  height: 1.2rem !important;
  padding: 0.5rem 0.25rem 0 0;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.edit-pencil-icon {
  padding: 0 0.25rem 0.45rem 0;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.link-icon {
  font-size: 1.4rem;
  color: #444444;
}

.link-icon-disabled {
  font-size: 1.4rem;
  color: #aaaaaa;
}

.link-icon-disabled {
  font-size: 1.4rem;
  color: #aaaaaa;
}

.active-link {
  color: #444444;
  background-color: #ececec;
}

.disabled-colour {
  color: #aaaaaa;
  cursor: auto;
}

.disable-cursor {
  cursor: auto !important;
}