.btn-enable-filled {
  background-color: #777777;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: #999999;
  }
}

.close-icon-filled {
  color: #ffffff;
  font-size: 1.1rem !important;
}
