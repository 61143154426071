.MuiPickersYear-yearButton {
    text-align: center !important;
}

.MuiInputBase-input {
    // color: #000000 !important;
    padding: 0 !important;
}

.css-12mi5e2-MuiInputBase-root-MuiOutlinedInput-root {
    padding-right: 0 !important; 
}
