.info-bar-container {
  background-color: #fafafa;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  padding: 0 0 1rem 0;
  height: auto;
  gap: 1rem;

  @media screen and (min-width:480px) {
    flex-direction: row;
    padding: 0;
    height: 4rem;
  }
}

.info-bar-nav-info-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  margin-left: 1rem;

  @media screen and (min-width:768px) {
    justify-content: start;
  }
}

.nav-spacer {
  height: 4rem;
  width: 4rem;
  display: block;

  @media screen and (min-width:768px) {
    display: none;
  }
} 

.user-info-panel {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width:480px) {
    align-items: start;
  }

  .user-name-time-panel {
    display: flex;
    gap: 0.5rem;
    padding: 0 0 0.2rem 0;

    .user-name {
      font-size: 1rem;
      font-weight: 600;
    }

    .user-time {
      font-size: 1rem;
      font-weight: 300;
      text-decoration: underline;
      color: #1e86f0;
    }
  }

  .user-location {
    font-size: 0.6rem;
    font-weight: 400;
  }
}

.user-setting-panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.3rem;
  margin: 0 1rem;

  .user-settings-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    .user-settings-icon {
      font-size: 1.9rem;
      color: #000000;
      cursor: pointer;

      &:hover {
        color: #1e86f0;
      }
    }

    .user-settings-label {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
}

.nav-container {
  height: 140vh;
  align-self: flex-start;
  z-index: 1;
}

.nav-button {
  background-color: #000000;
  color: #ffffff;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 300px;
  top: 0;
}

.user-name {
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.user-time {
  font-size: 1rem;
  font-weight: 300;
  color: black;
}

.user-location {
  font-size: 0.6rem;
  font-weight: 400;
  color: black;
  margin-left: 5px;
}

.log-out-button {
  margin-right: 0.7rem !important;
  margin-left: 0.3rem !important;

  @media screen and (min-width: 480px) {
    margin-left: 0.7rem !important;
  }
}

