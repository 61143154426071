@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
p {
  color: #000000;
}

ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Change the color of auto fill on Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 2rem white inset;
}

.content-container {
  display: flex;
  justify-content: start;
  width: 95vw;
  max-width: 900px;
  margin: 1.25rem auto 1.25rem auto;
}

@media screen and (min-width:480px) {
  .content-container {
    margin: 1.4rem auto 1rem auto
  }
}

button {
  all: unset;
}

.delete-report-button {
  height: 1.38rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #d32f2f;
  border: 0.09rem solid #d32f2f;
  padding: 0 0.4rem;
}

.delete-report-button:hover {
  color: #ffffff;
  background: #d32f2f;
}


.edit-button {
  background: #ffffff;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
}

.edit-button:hover {
  opacity: 0.6;
}

.delete-button {
  width: 77%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e86f0;
  border-radius: 0.3rem;
  margin: 3rem auto 1.4rem auto;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  border: 0.1rem solid #1e86f0;
}

.delete-button:hover {
  border: 0.1rem solid #8e1600;
  color: #8e1600;
}

.delete-button-confirm {
  background-color: #8e1600;
  width: 77%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 0.3rem;
  margin: 3rem auto 1.4rem auto;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  border: 0.1rem solid #8e1600;
}

.delete-button-confirm:hover {
  background-color: #631d11;
  border: 0.1rem solid #631d11;
  color: #ffffff;
}

/* MUI text field standard styling */
.css-a7i6a0-MuiInputBase-root-MuiInput-root {
  width: 260px;
  height: 2rem;
}

/* search modal container*/
.css-y57b29 {
  width: 90vw;
  max-width: 1000px;
}

/* Dark Mode Scrollbar on :root (html) */
:root.dark-mode {
  scrollbar-color: rgb(45, 46, 45) rgb(7, 6, 6);
  color-scheme: dark;
  /* Enable dark mode UI elements */
}

/* Styling for InfoWindow content in dark mode */
.custom-info-window.dark-mode {
  background-color: #2b2b2b;
  /* Dark background */
  color: #e0e0e0;
  /* Light text color */
}

.custom-info-window.dark-mode .cluster-data-panel {
  color: #e0e0e0;
  /* Ensure text color adapts for dark mode */
}

/* Google Maps InfoWindow container */
.gm-style-iw-c {
  background: #fff;
}

.dark-mode .gm-style-iw-c {
  background-color: #0c0a0a !important;
  /* Override to dark background */
  color: #e0e0e0 !important;
  /* Text color for dark mode */
}

.dark-mode .gm-ui-hover-effect>span {
  background-color: #ffffff !important;
}

/* downward arrow on bottom of map data popups */
.gm-style .gm-style-iw-tc::after {
  top: 0;
}
