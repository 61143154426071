.modal-warning {
  background-color: #fbebeb;
  display: flex;
  width: 100%;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0.85rem;
  border-radius: 0.35rem 0.35rem 0 0;
}

.modal-warning-text-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .error-icon {
    color: #d60202;
    font-size: 1.4rem;
  }

  .modal-warning-text {
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.modal-warning-span {
  color: #1e86f0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #4da6ff;
  }
}

.dialog-close-icon {
  color: #333333;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: #666666;
  }
}

.modal-parent-content-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  gap: 1rem;
  width: 90vw;
  padding: 0.8rem 0;
  position: relative;
  border-radius: 0.35rem 0.35rem 0.35rem 0.35rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    width: auto;
    padding: 1rem 3rem;
    gap: 0;
  }
}

.modal-parent-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.modal-parent-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin: 10px 25px;
  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
}

.modal-parent-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16rem;
  padding: 0.6rem;
  border-radius: 1.5rem;
  border: 0.1rem #777777 solid;
  font-size: 0.9rem;
}

.modal-parent-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 0.75rem;
  width: 90vw;

  @media screen and (min-width: 480px) {
    padding: 1rem 0;
    width: 100%;
  }

  .modal-search-clear {
    background-color: #777777;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #999999;
    }

    .search-close-icon {
      height: 1.1rem;
    }
  }

  .search-menu {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(204, 204, 204, 1) 100%
    );
    padding: 0.65rem 0.9rem;
    // border-radius: 1.2rem;
    border: 0.025rem #ccc solid;
    width: 13rem;
    cursor: pointer;
  }

  .modal-search-icon-panel {
    width: 2rem;
    height: 2rem;
    border: 0.1rem #cccccc solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-search-icon {
      color: #333333;
      cursor: pointer;
    }
  }
}

.modal-advance-panel {
  width: 11rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #1e86f0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #4da6ff;
  }
}

.modal-spacer-big {
  width: 11rem;
  height: 2rem;
  display: none;

  @media screen and (min-width: 480px) {
    display: block;
  }
}

.advanced-search-panel {
  width: 100%;
  margin-top: 1rem;
  border-radius: 0.35rem;
  padding: 3rem 0;
  position: relative;
  overflow-y: scroll;
  max-height: 600px;
  @media screen and (min-width: 480px) {
    padding: 5rem;
  }
}

.modal-advanced-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.inner-accordion {
  width: 100%;
  margin: auto;
}

.save-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 3rem;
  max-width: 600px;
  justify-content: space-between;
  margin: 2rem auto 0 auto;
  gap: 1rem;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
}

.save-panel-left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;

  @media screen and (min-width: 480px) {
    justify-content: flex-start;
  }

  .save-panel-left-block {
    display: flex;
    gap: 0.15rem;
    justify-content: center;
    align-items: center;

    .save-icon-block {
      display: flex;
      justify-content: center;
    }

    .save-icon {
      color: #333333;
      height: 1rem;
    }

    .save-search-button {
      font-size: 0.8rem;
      color: #333333;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #999999;
      }
    }

    .save-search-button-disabled {
      font-size: 0.8rem;
      color: red;
    }
  }
}

.save-panel-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;

  @media screen and (min-width: 480px) {
    justify-content: flex-end;
  }

  .save-panel-right-block {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    .clear-all-button {
      font-size: 0.8rem;
      color: #333333;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #999999;
      }
    }

    .clear-all-button-disabled {
      font-size: 0.8rem;
      text-decoration: none !important;
    }

    .clear-all-button-disabled {
      font-size: 0.8rem;
      // color: #c2c2c2;
      color: #d60202;
      text-decoration: underline;
    }

    .search-button {
      background: linear-gradient(180deg, #999999 0%, #333333 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      color: #ffffff;
      width: 10rem;
      height: 2.25rem;
      border-radius: 1.5rem;
      cursor: pointer;

      &:hover {
        background-color: #999999;
      }
    }

    .search-button-disabled {
      background: #c2c2c2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      color: #ffffff;
      width: 10rem;
      height: 2.25rem;
      border-radius: 1.5rem;
    }
  }
}

.MuiPaper-root-MuiAccordion-root {
  box-shadow: 0px !important;
}

.MuiAccordionDetails-root {
  padding: 0 1.5rem 1rem 1.5rem !important;

  @media screen and (min-width: 480px) {
    padding: 0 7rem 1.5rem 7rem !important;
  }
}

.accordion-menu-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.mobile-gap {
    gap: 0.75rem;

    @media screen and (min-width: 480px) {
      gap: 0;
    }
  }

  &.mobile-style {
    height: 8rem;
    margin-top: 1rem;

    @media screen and (min-width: 480px) {
      height: auto;
    }
  }

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }

  .date-to {
    font-size: 1rem;
    flex: 1;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .date-spacer {
    height: 1rem;
    width: 2rem;
    flex: 1;
  }
}

.accordion-spacer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 2.5;
  height: 3rem;
}

.accordion-menu {
  background: #ffffff;
  background: linear-gradient(180deg, #ffffff 0%, #ebebeb 100%);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 2.5;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  position: relative;

  &.accordion-border {
    border: 0.08rem #c2c2c2 solid;
  }

  .accordion-menu::after {
    content: "▼";
    font-size: 1rem;
    top: 6px;
    right: 10px;
    position: absolute;
  }

  select {
    background: #ffffff;
    background: linear-gradient(180deg, #ffffff 0%, #ebebeb 100%);
    display: flex;
    flex: 1.5;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding: 0 1rem;
    border-radius: 0.35rem;
    font-size: 1rem;
  }
}
