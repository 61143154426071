

.login-textfield {
    &.MuiInputBase-input{
    width: 23rem !important;
    color: #000000 !important;
    padding: 1rem !important;
    }
    @media screen and (min-width: 480px) {
        width: 15rem !important;
      }
}

.geosafe-logo-image {
  width: 60vw;
  margin: 0 auto 2rem auto;

  @media screen and (min-width: 480px) {
    width: 25rem;
    margin: 0 auto 3rem auto;
  }
}
