html {
  scroll-behavior: smooth;
}

.page-container {
  background-color: #ececec;

  padding: 1rem;
}

.page-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  scroll-behavior: smooth;
}

.page-header {
  font-size: 2.5rem;
  font-weight: 900;
}

.glossary-nav-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  margin: 0.75rem 0 2rem 0;
  position: sticky;
  top: 3.5rem;
  padding: 1rem 0;
  background-color: #ececec;

  @media screen and (min-width:480px){
    margin: 0.75rem 0 2.5rem 0;
    top: 4rem;
  }
}

.glossary-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  section {
    width: 100%;
    scroll-margin-top: 10rem;

    @media screen and (min-width:480px){
      scroll-margin-top: 7.75rem;
    }

    .glosary-list-subhead {
      font-size: 1.65rem;
      font-weight: 600;
      margin: 0.5rem 0 0 0;
      padding: 0;
    }
  }
}

.glossary-list-text-panel {
  padding: 0 0 0.25rem 0;

  .glossary-list-text-subhead {
    font-size: 1rem;
    font-weight: 600;
    border-top: 0.1rem solid #989898;
    padding: 0.35rem 0 0 0;
  }

  .glossary-list-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 0 0 0.85rem 0;
  }
}
