.footer-box {
  background-color: #d8d8d8;
  width: 100%;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  gap: 0.9rem;
  margin: 0;
  position: sticky;
  bottom: -10.625rem;

  @media screen and (min-width: 480px) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
}

.footer-panel {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .geosafe-footer-logo {
    width: 9rem;
  }

  .footer-panel-version {
    font-size: 1.313rem;
    color: #7b7b7b;

    @media screen and (min-width: 480px) {
      font-size: 1.5rem;
    }
  }
}

.footer-panel-copyright {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  color: #7b7b7b;
  width: 100%;

  @media screen and (min-width: 480px) {
    justify-content: flex-end;
  }
}