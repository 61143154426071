.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.popup-container {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    width: 400px;
    max-width: 90%;
}

.popup-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    gap: 10px;
}

.popup-btn {
    padding: 10px 20px;
    border: 2px solid black;
    /* Add a black border */
    border-radius: 4px;
    background: none;
    /* Remove background color */
    color: black;
    /* Set text color to black */
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 120px;
    /* Ensure buttons are the same width */
    text-align: center;
}

.popup-btn:hover {
    background: rgba(0, 0, 0, 0.1);
    /* Optional hover effect */
}

.popup-container h2 {
    color: black !important;
    /* Ensure the title uses the same text color */
    font-size: 20px;
    margin-bottom: 10px;
}