.no-data-image {
  height: 5rem;
  margin: 0 0 1rem 0;

  @media screen and (min-width: 480px) {
    height: 6.5rem;
  }
}

.no-data-text {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  width: 90vw;
  max-width: 30rem;

  &.small {
    font-size: 1rem;
    width: 85vw;
    max-width: 25rem;
    margin-top: 3rem;
  }
}

.contact-button {
  color: #1e86f0;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 0.35rem;

  &:hover {
    opacity: 0.7;
  }
}
