.css-vprqbs {
  background-color: #ffffff !important;
  display: flex;
  flex-direction: column;
  justify-content: auto;
  height: 12rem !important;

  @media screen and (min-width: 480px) {
    flex-direction: row;
    justify-content: center;
    height: 64px !important;
  }
}

.search-input-container {
  width: 90vw;
  display: flex;
  margin: 1.5rem 0 0 0;

  @media screen and (min-width: 480px) {
    width: 100%;
    margin: 0;
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 85vw;

  @media screen and (min-width: 480px) {
    width: auto;
  }
}