.list-container {
  display: flex;
  margin-bottom: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: auto;
}

.listview {
  position: relative;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.custom-toolbar-button {
  color: blue !important;
}

.custom-toolbar-icon {
  fill: blue !important;
}

/* Hide icons for Columns and Filters buttons */
.custom-toolbar-icon .MuiSvgIcon-root {
  display: none !important;
}

@media print {

  /* Hide elements that are not needed in print */
  .MuiIconButton-root {
    display: none;
  }

  /* Ensure table/grid takes full width for print */
  .MuiDataGrid-root {
    width: 100% !important;
    color-adjust: exact;
  }

  /* Adjust the row heights and ensure cells are printed properly */
  .MuiDataGrid-row {
    height: auto !important;
  }

}

.css-q68e8e-MuiFormControl-root-MuiDataGrid-filterFormColumnInput {
  width: 250px !important;
  color: white !important;
  /* Use !important if needed */
  margin-right: 10px !important;
}

.css-17vwkjt-MuiFormControl-root-MuiDataGrid-filterFormOperatorInput {
  width: 200px !important;
  margin-right: 10px !important;
}

.css-1h08ml-MuiFormControl-root-MuiDataGrid-filterFormValueInput {
  width: 200px !important;
  // margin-bottom: 105px !important;
}

.css-a7i6a0-MuiInputBase-root-MuiInput-root {
  height: 1.45rem !important;
}

.css-1sjjn1c-MuiDataGrid-panelContent {
  width: 800px !important;
}

.css-17idfle-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-17idfle-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-17idfle-MuiSelect-select-MuiInputBase-input-MuiInput-input{
  color: #ffffff !important;
}